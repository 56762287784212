import { For } from 'solid-js';
import type { ShortResponseDto } from '~/libs/api-client';
import { display } from '~/utils';
import { CarouselGallery, Tile } from '../shared';
import { Slide } from '../ui';

interface ShortsGalleryProps {
  items: ShortResponseDto[];
}

export function ShortsGallery(props: ShortsGalleryProps) {
  return (
    <CarouselGallery
      id={'shorts-gallery'}
      heading={{
        title: 'Community Shorts',
        action: {
          url: '/shorts/',
          label: 'View all',
          hide: !display('viewAllVertical', props.items?.length ?? 0),
        },
      }}
    >
      <For each={props.items}>
        {item => (
          <Slide class="df-scrollable-list-item-4-col">
            <Tile
              preset="VerticalTileCondensedNoGenre"
              content={item}
              trackingProperties={{ section: 'shorts-carousel' }}
            />
          </Slide>
        )}
      </For>
    </CarouselGallery>
  );
}
